export default {
	messages: {
		ru: {
			'Пропозиції продавця': 'Предложения продавца',
			'Показати ще': 'Показать еще',
			'Подивитися всі': 'Посмотреть все',
			'Перевірений': 'Проверенный',
		},
		uk: {
			'Пропозиції продавця': 'Пропозиції продавця',
			'Показати ще': 'Показати ще',
			'Подивитися всі': 'Подивитися всі',
			'Перевірений': 'Перевірений',
		},
	}
};
