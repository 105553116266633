import { mapGetters } from 'vuex';
const { getSvg } = require('../../svg/carsBrands');
export default {
	i18n: require('./i18n').default,
	components: {
		Flickity: require('../../Common/Flickity/Flickity.vue').default,
	},
	data() {
		return {
			flickityOptions: {
				cellAlign: 'left',
				freeScroll: true,
				lazyLoad: true,
				pageDots: false,
				contain: true,
			},
			svg: {},
			index: 0,
		}
	},
	computed: {
		...mapGetters({
			getCompany: 'companies/company'
		}),
		id() {
			return this.$route.params.id;
		},
		company() {
			return this.getCompany(this.id) || {};
		},
		marks() {
			return this.company.marks || [];
		},
		searchUrl() {
			const {id, type: {id: typeId} = {}} = this.company;
			return typeId === 3 ?
				`/search-order-auto/?company_id=${id}&custom=3&abroad=1&marka_id=` :
				`/search/?company_id=${id}&custom=3&abroad=2&marka_id=`;
		},
	},
	watch: {
		marks: {
			handler(newValue = []) {
				if (newValue.length) {
					let brands = newValue.map(({eng = ''}) => eng);
					brands.push('no-logo');
					this.svg = getSvg(brands);
				}
			},
			immediate: true,
		}
	},
	methods: {
		flickityEvents(event, listener) {
			this.$refs.flickity.on(event, listener);
		},
		changeSlides() {
			this.flickityEvents('change', (index) =>{
				this.$emit('slonikLogs', 880, this.index < index ? 2 : 3, 5);
				this.index = index;
			});
		},
	},
};
