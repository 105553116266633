export default {
    messages: {
        ru: {
            'Додаткові послуги компанії': 'Дополнительные услуги компании',
        },
        uk: {
            'Додаткові послуги компанії': 'Додаткові послуги компанії',
        },
    }
};
