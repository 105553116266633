import { mapActions, mapGetters } from "vuex";

export default {
    name: 'PhonePopup',
    components: {
        Popup: require('../../Common/Popup.vue').default,
        RateSeller: require('../../Common/RateSeller/index.vue').default,
    },
    computed: {
        ...mapGetters({
            getCompany: 'companies/company',
        }),
        id() {
            return this.$route.params.id;
        },
        company() {
            return this.getCompany(this.id) || {};
        },
        phones() {
            return this._result(this.company, 'owner.phones', {});
        },
        ownerId() {
            return this._result(this.company, 'owner.id', 0);
        },
        type() {
            const { type: { id } = {} } = this.company;
            switch (id) {
                case 1:
                    return this.$t('Авто от банка');
                case 2:
                    return this.$t('Автодилер');
                case 3:
                    return this.$t('Авто под заказ');
            }
        },
        companyName() {
            return this._result(this.company, 'name.original', '');
        }
    },
    methods: {
        ...mapActions({
            close: 'popups/close',
            openPopup: 'popups/open',
        }),
    },
    i18n: require('../i18n').default,
}
