import { getSvg } from '../../svg/AdditionalService'

export default {
    name: 'AdditionService',
    props: {
        labels: Array,
    },
    computed: {
        showBlock() {
            return this.labels.some(({active}) => active);
        },
        svg() {
            if (this.showBlock) {
                const activeLabels = this.labels.filter(({active}) => active).map(({labelId}) => `labelId-${labelId}`);
                return getSvg(activeLabels)
            } else {
                return {};
            }
        },
    },
    i18n: require('./i18n').default,
}
