import Vue from 'vue';
import seo from './seo';


const la = require('../_laxios');

module.exports = {
    namespaced: true,
    state: Object.assign({
        base: {}
    }, __INITIAL_STATE__.companies),
    actions: {
        company({commit, rootGetters, getters}, id) {
            let {'lang/id': langId} = rootGetters;
            la.company.stay = () => !id || getters.company(id);
            return la.company.expandUrl({id}).request({params: {langId}})
                .then((payload) => {
                    commit('company', payload);
                    return payload;
                })
                .catch(console.error);
        },
        logVisit({}, id) {
            return la.companyLogVisit
                .expandUrl({id})
                .request()
                .then(res => res)
                .catch(e => console.log('logVisit: ', e))

        }
    },
    mutations: {
        company({base = {}} = {}, payload = {}) {
            const {id, name: {eng} = {}} = payload;
            if (id) {
                Vue.set(base, id, payload);
            }
        }
    },
    getters: {
        company({base = {}} = {}) {
            return function (id) {
                return base[id];
            };
        }
    },
    modules: {
        seo,
    },
};
