export default {
    name: 'AdvLinks',
    props: {
        data: Object,
    },
    computed: {
        links() {
            return this._result(this.data, 'item', []);
        },
        miniSiteUrl() {
            const link = this.data.minisiteUrl || '';
            const isTrueLink = /https\.ria\.biz$|null\.ria\.biz$|/.test(link);
            return isTrueLink ? '' : link;
        },
    }
}
