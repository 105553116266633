import { mapActions, mapGetters } from "vuex";

export default {
    name: 'RateSeller',
    props: {
        sellerId: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    computed: {
        ...mapGetters({
            sellerRating_: 'impression/sellerRating'
        }),
        sellerRating() {
            return this.sellerRating_(this.sellerId) || {}
        },
        myRate() {
            return {
                value: this._result(this.sellerRating, 'myRate.rating', 0),
                isRated: Boolean(this._result(this.sellerRating, 'myRate.rating', 0)),
            };
        },
    },
    methods: {
        ...mapActions({
            openPopup_: 'popups/open',
            close: 'popups/close',
            getMyRate_: 'impression/getMyRate',
        }),
        openPopup(e) {
            const value = Number(this._result(e, 'target.value', 0));
            this.close();
            this.openPopup_({
                name: 'ImpressionPopup', type: 'seller', data: {
                    rate: value,
                    sellerId: this.sellerId,
                }
            });
        },
        getMyRate() {
            if (this.sellerId) {
                this.getMyRate_(this.sellerId)
            }
        },
    },
    mounted(){
        this.getMyRate();
    },
}
