module.exports = {
	props: {
		src: String
	},
	data() {
		return {};
	},
	methods: {
		init() {
			require('pannellum');
		},
		checkLoad(viewer) {
			const interval = setInterval(function () {
				if (viewer.isLoaded()) {
					this.$emit('loaded');
					clearInterval(interval);
				}
			}.bind(this), 333);
		},
		draw() {
			this.$nextTick(() => setTimeout(() => {
				const viewer = window.pannellum.viewer('panorama', {
					panorama: this.src,
					autoLoad: true,
					autoRotate: -2,
					autoRotateInactivityDelay: 3000,
					compass: false,
				});

				this.viewer = viewer;

				this.checkLoad(viewer);
			}));
		}
	},
	beforeUpdate() {
		this.draw();
	},
	mounted() {
		this.init();
		this.draw();
		try {
			const IOW = require('../../../../helpers/IntersectionObserver_screen__75');
			IOW.add(this.$el, function (entry = {}) {
				if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
					if (this.viewer && this.viewer.startAutoRotate) {
						this.viewer.startAutoRotate();
					}
				} else {
					if (this.viewer && this.viewer.stopAutoRotate) {
						this.viewer.stopAutoRotate();
					}
				}
			}.bind(this));
		} catch (error) {
			console.error(error);
		}
	}
};
