import getDeviceOs from "../../../../helpers/getDeviceOs";
import {get} from "../../../../helpers/cookie";

const {mapActions, mapGetters} = require('vuex');

import logoAPP from './icon/application.svg';
import logoTelegram from './icon/telegram.svg';
import logoEmail from './icon/email.svg';

export default {
    name: 'Subscription',
    data() {
        return {
            showEmailSubscriber: false,
            alreadySigned: false,
            os: getDeviceOs(),
            keyHash: '',
            userId: '',
            webClientId: '',
            userEmail: '',
            emailVerified: '',
            logoAPP,
            logoTelegram,
            logoEmail,
        };
    },
    methods: {
        ...mapActions({
            createHash: 'search/state2/createHash',
            createEmailSub: 'search/state2/createEmailSub',
            getUserData: 'Common/userData',
        }),
        logger(data) {
            this._slonik({
                event_id: 1024,
                action_block: 2,
                ...data,
            });
        },
        sendGa(platform) {
            this.gaEvent('Dealers', platform, 'bu_Social_networking_service');
        },
        createKeyHash(userId, webClientId) {
            let sq = `https://auto.ria.com/search/?dealer.id=${this.$route.params.id}&abroad.not=0&custom.not=-1`;
            if (userId) {
                sq += `&subscriberId=${userId}`;
            }
            if (webClientId) {
                sq += `&webId=${webClientId}`;
            }
            this.createHash(encodeURIComponent(sq))
                .then((data) => {
                    if (data.key && !data.error) {
                        this.keyHash = data.key;
                    }
                });
        },
        subscribeTelegram() {
            window.open(`https://telegram.me/AutoRiaSearchBot?start=${this.keyHash}`, '_blank');
        },
        subscribeAPPiOS() {
            let windowReference = window.open();
            windowReference.location = `https://autoriaios.page.link/?link=https%3A%2F%2Fauto%2Eria%2Ecom%2F%3Ftarget%3DsubscribeOnSearch%26SearchURLHashMD5%3D${this.keyHash}&ibi=auto%2Eria&isi=1083363297`;
        },
        subscribeEmail(payload) {
            if (this.emailVerified) {
                this.createEmailSub(payload)
                    .then((data) => {
                        if (data.error) {
                            this.alreadySigned = true;
                        }
                    })
            }
        },
        createNewSubscribe(target) {
            const dealerId = this.$route.params.id;
            const payload = {
                searchQuery: `indexName=auto,order_auto,newauto_search&country.import.usa.not=-1&price.currency=1&abroad.not=0&custom.not=-1&dealer.id=${dealerId}&page=0&size=${Number(get('ipp')) || 20}`,
                mailing_email: this.userEmail,
            };

            switch (target) {
                case 'telegram':
                    this.sendGa('Telegram');
                    this.subscribeTelegram();
                    this.logger({ subscription_search_channel: 3, company_id: dealerId });
                    break;
                case 'appAndroid':
                    this.sendGa('App');
                    this.logger({ subscription_search_channel: 7, company_id: dealerId });
                    break;
                case 'appIOS':
                    this.sendGa('App');
                    this.subscribeAPPiOS();
                    this.logger({ subscription_search_channel: 7, company_id: dealerId });
                    break;
                case 'email':
                    this.showEmailSubscriber = true;
                    this.sendGa('Email');
                    this.subscribeEmail(payload);
                    this.logger({ subscription_search_channel: 4, company_id: dealerId });
                    break;
                default:
                    break;
            }
        },
        close(){
            this.showEmailSubscriber = false;
        }
    },
    mounted() {
        this.getUserData()
            .then((data) => {
                this.userId = data.userId || '';
                this.webClientId = data.webClientId || '';
                this.userEmail = data.userEmail || '';
                this.emailVerified = data.emailVerified || '';
                this.createKeyHash(this.userId, this.webClientId);
            });
    },
    i18n: {
        messages: {
            uk: {
                'Вітаємо!': 'Вітаємо!',
                'Підписка не створена': 'Підписка не створена',
                'Підтвердити': 'Підтвердити',
                'Вказати': 'Вказати',
                'Увійти': 'Увійти',
                'Додаток': 'Додаток',
                'Підтвердіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції': 'Підтвердіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції',
                'Вкажіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції': 'Вкажіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції',
                'Тепер ви будете отримувати на': 'Тепер ви будете отримувати на',
                'всі свіжододані авто': 'всі свіжододані авто',
                'Підписка на e-mail доступна лише для авторизованих користувачів. Зареєструйтесь на AUTO.RIA або увійдіть та повторіть спробу': 'Підписка на e-mail доступна лише для авторизованих користувачів. Зареєструйтесь на AUTO.RIA або увійдіть та повторіть спробу',
                'Ви вже підписані на': 'Ви вже підписані на',
            },
            ru: {
                'Вітаємо!': 'Поздравляем!',
                'Підписка не створена': 'Подписка не создана',
                'Підтвердити': 'Подтвердить',
                'Вказати': 'Указать',
                'Увійти': 'Войти',
                'Додаток': 'Приложение',
                'Підтвердіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції': 'Подтвердите в своей анкете e-mail, на который хотели бы получать свежие предложения',
                'Вкажіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції': 'Укажите в своей анкете e-mail, на который хотели бы получать свежие предложения',
                'Тепер ви будете отримувати на': 'Теперь вы будете получать на',
                'всі свіжододані авто': 'все свежедобавленные авто',
                'Підписка на e-mail доступна лише для авторизованих користувачів. Зареєструйтесь на AUTO.RIA або увійдіть та повторіть спробу': 'Подписка на e-mail доступна только авторизованным пользователям. Зарегистрируйтесь на AUTO.RIA или войдите и повторите попытку',
                'Ви вже підписані на': 'Вы уже подписаны на',
            }
        }
    }
};
