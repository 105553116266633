const {mapActions, mapGetters} = require('vuex');
const storeModule = require('../../store/companies');
const {images: {jpeg}} = require('../../helpers/patterns');
const popupsStoreModule = require('../../store/cabinet/advertisements/popups');
import { TYPE_ID } from '../../../constants/companies';

module.exports = {
    i18n: require('./i18n').default,
    init({store, route = {}, target, context}) {
        store.registerModule('companies', storeModule);
        store.registerModule('popups', popupsStoreModule);

        if (target === 'node') {
            const {to: {params: {id} = {}} = {}} = route;
            return Promise.all([
                store.dispatch('companies/company', id)
                    .then((company) => {
                        let nowMilisec = new Date().getTime(),
                            {package: {closeDate = undefined} = {}} = company || {},
                            closeDateMilisec = closeDate ? new Date(closeDate).getTime() : null;

                        if (closeDateMilisec && (nowMilisec > closeDateMilisec)) {
                            context.response.redirect("/dealers/");
                        }
                        return company;
                    }),
                store.dispatch('companies/seo/fetch', {id}),

            ])
        }
    },
    components: {
        Breadcrumbs: require('../Common/Breadcrumbs/index.vue').default,
        PhotoBlock: require('./PhotoBlock/index.vue').default,
        Brands: require('./Brands/index.vue').default,
        Offers: require('./Offers/index.vue').default,
        'aside-block': require('./AsideBlock/index.vue').default,
        'google-map': require('../../components/Common/GoogleMap/index.vue').default,
        PhonePopup: require('./PhonePopup/index.vue').default,
        ImpressionPopup: require('../Common/ImpressionPopup/index.vue').default,
        'adv-links': require('./AdvLinks/index.vue').default,
        'additional-service': require('./AdditionalService/index.vue').default,
        'subscription': require('./AsideBlock/Subscription/index.vue').default,
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [
                {property: 'og:url', content: `https://auto.ria.com${this.$route.path}`},
                {property: 'og:title', content: `${this.name} на AUTO.RIA`},
                {property: 'og:image', content: `https://cdn.riastatic.com/photos/${this.logo.replace(jpeg, 'q.$1')}`},
                {property: 'og:type', content: 'website'},
                {
                    property: 'og:description',
                    content: `${this.type} ${this.$t('в городе')} ${this.cityName}, ${this.autoCount} авто`
                },
                {property: 'fb:app_id', content: '214702429075634'},
                {name: 'description', content: this.description},

            ],
            link: [
                {rel: 'canonical', href: this.canonical},
            ]
        };
    },
    data() {
        return {
            showMap: false,
            googleMapConfig: {
                zoom: 14,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
            },
            mapStatus: false,
            showMoreText: false,
        };
    },
    computed: {
        ...mapGetters({
            getCompany: 'companies/company',
            getSeo: 'companies/seo/getSeo',
            popup: 'popups/popup',
            windowWidth: 'shared/device/width'
        }),
        id() {
            return this.$route.params.id;
        },
        typeLink() {
            return this.$route.params.type;
        },
        company() {
            return this.getCompany(this.id) || {};
        },
        seo() {
            return this.getSeo(this.id) || {};
        },
        title() {
            return this.seo.title || '';
        },
        description() {
            const description = this.seo.description || '';
            const count = this.company.count || '';
            return description.replace(/__count__/gi, `${count}`);
        },
        canonical() {
            return this.seo.link || '';
        },
        name() {
            const {name: {original} = {}} = this.company;
            return original;
        },
        cityName() {
            const {location: {region: {name} = {}} = {}} = this.company;
            return name;
        },
        isAvtovikupCompany() {
            const {
                type: { id: typeId } = {},
                labels = []
            } = this.company;
            const isBasicDealer = typeId === TYPE_ID.BASIC_DEALER;
            const hasAvtovikupService = Boolean(labels.find((item) => item?.labelId === 7));
            const isPathIncludesAvtovikup = this.$route.path.includes('/avtovikup/');
            return isBasicDealer && hasAvtovikupService && isPathIncludesAvtovikup;
        },
        type() {
            const {type: {id} = {}} = this.company;
            const withAvtovikupServiceText = this.isAvtovikupCompany
                ? ` ${this.$t('з послугою Автовикупу')}`
                : '';
            switch (id) {
                case TYPE_ID.BANK:
                    return this.$t('Авто от банка');
                case TYPE_ID.BASIC_DEALER:
                    return `${this.$t('Автодилер')}${withAvtovikupServiceText}`;
                case TYPE_ID.ORDER_AUTO_DEALER:
                    return this.$t('Авто под заказ');
            }
        },
        autoCount() {
            const {count = {}} = this.company;
            return count;
        },
        logo() {
            const {logo = ""} = this.company;
            return logo || "";
        },
        geo() {
            return {
                lat: Number(this._result(this.company, 'location.geo.X', 0)),
                lng: Number(this._result(this.company, 'location.geo.Y', 0))
            }
        },
        address() {
            return `${this._result(this.company, 'location.city.name', '')}, ${this._result(this.company, 'location.address', '')}`
        },
        adsInfo() {
            let adsInfo = this._result(this.company, 'adsInfo', {});
            Object.assign(adsInfo, {minisiteUrl: this.company.minisiteUrl});
            return adsInfo;
        },
        breadcrumbsList() {
            return [{
                path: this.typeLink, title: this.type
            }]
        }
    },
    methods: {
        ...mapActions({
            fetch: 'companies/company',
            fetchSeo: 'companies/seo/fetch',
            logVisit: 'companies/logVisit',
            open: 'popups/open',
            close: 'popups/close',
        }),
        setMapCenterByAddress(address) {
            this.$refs.googleMap.setCenterByAddress(address);
        },
        setMapStatus(status) {
            this.mapStatus = status;
        },
        isShowMoreText() {
            const descriptionHeight = this._result(this.$refs, 'content.clientHeight', 0);
            this.showMoreText = descriptionHeight > (this.isMobile ? 190 : 115);
        },
        sendLogsToSlon(event, action, block, payload = {}) {
            this._slonik(Object.assign({
                event_id: event,
                click_action: action,
                action_block: block,
                previous_url: action ? undefined : document.referrer,
                company_id: this.id,
                company_type_id: this.company.type?.id,
                screentype: this.isMobile ? 2 : 1,
                project_id: 1,
            }, payload));
        },
    },
    mounted() {
        this.fetch(this.id);
        this.isShowMoreText();
        this.logVisit(this.id);
        this.sendLogsToSlon(879);
    },
    watch: {
        mapStatus(status) {
            // Ставимо маркер по адресу, якщо немає геокординатів
            if (status && !this.geo.lat && !this.geo.lng) {
                this.setMapCenterByAddress(this.address);
            }
        },
        windowWidth() {
            if (this.isMobile) {
                this.isShowMoreText();
            }
        }
    }
};
