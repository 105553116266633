const { mapGetters, mapActions } = require('vuex');

export default {
  name: 'AsideBlock',
  components: {
    phone: require('../Phone/index.vue').default,
    subscription: require('./Subscription/index.vue').default,
  },
  props: {
    type: String,
  },
  computed: {
    ...mapGetters({
      getCompany_: 'companies/company',
      sellerRating_: 'impression/sellerRating',
    }),
    id() {
      return this.$route.params.id;
    },
    company() {
      return this.getCompany_(this.id) || {};
    },
    schedule() {
      return this._result(this.company, 'schedule', []);
    },
    owner() {
      return this._result(this.company, 'owner', {});
    },
    location() {
      return this._result(this.company, 'location', {});
    },
    city() {
      return this._result(this.location, 'city', '');
    },
    cityName() {
      return this._result(this.city, 'name', '');
    },
    address() {
      return this._result(this.location, 'address', '');
    },
    addressStr() {
      return [this.cityName, this.address].filter(Boolean).join(', ');
    },
    sellerRating() {
      return this.sellerRating_(this.owner.id) || {};
    },
    logo() {
      return this.company.logo ? `https://cdn.riastatic.com/photos/${this.company.logo.replace(/\.(.+$)/, 'q.$1')}` : 'https://img6.auto.ria.com/images/nophoto/avatar-50x50.png';
    },
    golinkUrl() {
      return this.company.siteUrl ? `/golink/?${this._result(this.company, 'siteUrl', {})}` : '';
    },
    phones() {
      return this.owner.phones || {};
    },
    isDealerOnline() {
      const format = 'YYYY-MM-DD HH:mm:ss';
      const { lastVisit } = this.owner;
      const fiveMinutesAfter = this.m(lastVisit).add(5, 'minutes').format(format);
      const time = this.m(new Date(), format);
      return time.isBetween(lastVisit, fiveMinutesAfter);
    },
    workSchedule() {
      return this.schedule.find((day) => day?.weekDay === this.m(new Date()).day());
    },
  },
  methods: {
    ...mapActions({
      getSellerRating_: 'impression/getSellerRating',
    }),
    openChat() {
      if (window.riaChat && typeof window.riaChat.showChat === 'function') {
        window.riaChat.showChat();
        this.slonikElevator(880, 3, 1);
      }
    },
    slonikElevator(event, action, block) {
      this.$emit('slonikLogs', event, action, block);
    },
  },
  mounted() {
    this.getSellerRating_(this.owner.id);
  },
  i18n: require('./i18n').default,
};
