const { findElements } = require('../functions');

const svg = {
    // Trade-In
    'labelId-1': '<circle cx="12" cy="12" r="12" fill="#256799"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C12 5 8.57143 6.75 6 6.75V9.375C6 14.625 8.57143 17.25 12 19C15.4286 17.25 18 14.625 18 9.375V6.75C15.4286 6.75 12 5 12 5ZM11.1 14.4909L15.1243 10.2227L14.2758 9.32275L11.1 12.691L9.72431 11.2318L8.87578 12.1318L11.1 14.4909Z" fill="white"/>',
    // Кредит
    'labelId-2': '<circle cx="12" cy="12" r="12" fill="#256799"/><g clip-path="url(#clip0_0_1)"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.6264 13.2928L14.0406 14.707L12.0811 16.6666L14.0406 18.6261L12.6264 20.0404L9.25265 16.6666L12.6264 13.2928Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5416 8.80055C15.0634 9.07669 14.8995 9.68828 15.1756 10.1666C15.4974 10.724 15.6669 11.3563 15.6669 11.9999C15.6669 12.6435 15.4974 13.2758 15.1756 13.8332C14.8538 14.3906 14.3909 14.8535 13.8335 15.1753C13.2761 15.4972 12.6438 15.6666 12.0002 15.6666C11.4479 15.6666 11.0002 16.1143 11.0002 16.6666C11.0002 17.2189 11.4479 17.6666 12.0002 17.6666C12.9949 17.6666 13.9721 17.4047 14.8335 16.9074C15.695 16.41 16.4103 15.6947 16.9077 14.8332C17.405 13.9718 17.6669 12.9946 17.6669 11.9999C17.6669 11.0052 17.405 10.028 16.9077 9.16658C16.6315 8.68828 16.0199 8.52441 15.5416 8.80055Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.3739 10.707L9.95972 9.29281L11.9193 7.33325L9.95972 5.37369L11.3739 3.95947L14.7477 7.33325L11.3739 10.707Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.45871 15.1993C8.937 14.9231 9.10088 14.3115 8.82474 13.8333C8.50292 13.2758 8.3335 12.6436 8.3335 11.9999C8.3335 11.3563 8.50292 10.724 8.82474 10.1666C9.14655 9.60918 9.60943 9.14631 10.1668 8.82449C10.7242 8.50267 11.3565 8.33325 12.0002 8.33325C12.5524 8.33325 13.0002 7.88554 13.0002 7.33325C13.0002 6.78097 12.5524 6.33325 12.0002 6.33325C11.0055 6.33325 10.0283 6.59509 9.16683 7.09244C8.30539 7.58979 7.59004 8.30514 7.09269 9.16658C6.59533 10.028 6.3335 11.0052 6.3335 11.9999C6.3335 12.9946 6.59533 13.9718 7.09269 14.8333C7.36883 15.3115 7.98042 15.4754 8.45871 15.1993Z" fill="white"/></g><defs><clipPath id="clip0_0_1"><rect width="16" height="16" fill="white" transform="translate(4 4)"/></clipPath></defs>',
    // Страхування
    'labelId-3': '<circle cx="12" cy="12" r="12" fill="#256799"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.8571 5H8.14286C6.94286 5 6 6.03529 6 7.35294V15.8235C6 17.1412 6.94286 18.1765 8.14286 18.1765H12L14.5714 21V18.1765H15.8571C17.0571 18.1765 18 17.1412 18 15.8235V7.35294C18 6.03529 17.0571 5 15.8571 5ZM12.0001 7.35297C12.2763 7.35297 12.5001 7.56366 12.5001 7.82355V7.99461C13.7176 8.14461 14.5 8.84214 14.5 9.70588C14.5 9.96578 14.2762 10.1765 14 10.1765C13.7239 10.1765 13.5 9.96578 13.5 9.70588C13.5 9.49786 13.2872 9.07166 12.5001 8.94328V11.0981C13.0317 11.1596 13.4863 11.3253 13.834 11.6093C14.2966 11.9872 14.5 12.5171 14.5 13.0941C14.5 13.6712 14.2966 14.2011 13.834 14.5789C13.4863 14.8629 13.0317 15.0286 12.5001 15.0901V15.353C12.5001 15.6129 12.2763 15.8236 12.0001 15.8236C11.724 15.8236 11.5001 15.6129 11.5001 15.353V15.0906C10.9733 15.0312 10.5314 14.8751 10.1926 14.6344C9.74407 14.3159 9.50002 13.8626 9.50002 13.3765C9.50002 13.1166 9.72388 12.9059 10 12.9059C10.2762 12.9059 10.5 13.1166 10.5 13.3765C10.5 13.5491 10.581 13.7312 10.795 13.8832C10.9473 13.9914 11.1767 14.0895 11.5001 14.1402V11.9842C10.9684 11.9228 10.5138 11.7571 10.166 11.473C9.70346 11.0952 9.50002 10.5653 9.50002 9.98823C9.50002 9.41119 9.70346 8.88129 10.166 8.50342C10.5138 8.21937 10.9684 8.05367 11.5001 7.99223V7.82355C11.5001 7.56366 11.724 7.35297 12.0001 7.35297ZM10.8215 9.21421C10.9657 9.09647 11.1834 8.9952 11.5001 8.94319V11.0333C11.1834 10.9813 10.9657 10.88 10.8215 10.7622C10.6216 10.5989 10.5 10.3524 10.5 9.98823C10.5 9.6241 10.6216 9.37753 10.8215 9.21421ZM12.5001 14.1391V12.0491C12.8167 12.1011 13.0344 12.2024 13.1785 12.3201C13.3785 12.4834 13.5 12.73 13.5 13.0941C13.5 13.4582 13.3785 13.7048 13.1785 13.8681C13.0344 13.9858 12.8167 14.0871 12.5001 14.1391Z" fill="white"/>',
    // Послуги СТО
    'labelId-4': '<circle cx="12" cy="12" r="12" fill="#256799"/><path d="M6.46376 8.43255C6.39373 8.43255 6.3288 8.39592 6.29258 8.33599L5.11371 6.38526C5.06598 6.30628 5.07848 6.2049 5.14396 6.13989L5.80137 5.48719C5.86614 5.42288 5.96623 5.41058 6.04464 5.45729L8.01138 6.62889C8.07192 6.66495 8.10902 6.73023 8.10902 6.80071V7.47654C8.10902 7.52983 8.13029 7.58092 8.16811 7.61846L10.3551 9.78978C10.3646 9.79924 10.3732 9.80969 10.3807 9.8208C10.4443 9.91436 10.474 9.94192 10.4878 9.9564C10.5093 9.97882 10.4928 10.0145 10.4789 10.0422L10.1193 10.7615C10.0596 10.8807 9.90221 10.9094 9.80442 10.8187L7.2875 8.48586C7.25052 8.45159 7.20196 8.43255 7.15155 8.43255H6.46376ZM15.8261 13.782C15.7895 13.7494 15.7421 13.7314 15.693 13.7314C15.5443 13.7314 15.4288 13.7314 15.2669 13.7314C14.8699 13.7314 14.5242 13.6805 14.1833 13.6247C14.1153 13.6136 14.0462 13.6381 14.0006 13.6899L12.8727 14.9697C12.8058 15.0457 12.8061 15.1597 12.8736 15.2352L15.7064 18.4068C16.3971 19.1549 17.5901 19.2172 18.2808 18.4691C19.0342 17.7211 18.9715 16.599 18.218 15.9132L15.8261 13.782ZM17.9041 11.3625C18.9087 10.3651 19.2226 8.86892 18.8459 7.5598C18.785 7.31786 18.4876 7.25198 18.3553 7.41909C18.3471 7.42943 18.3389 7.4398 18.3295 7.44909L16.2869 9.47709C16.2089 9.55452 16.083 9.55452 16.0051 9.47709L14.5308 8.01342C14.4521 7.9352 14.4521 7.80779 14.5308 7.72957L16.5855 5.68963C16.7739 5.50261 16.7111 5.25325 16.4599 5.19091C15.1414 4.75454 13.6344 5.06624 12.6298 6.126C11.5367 7.21129 11.2659 8.87975 11.8174 10.2289C11.8507 10.3105 11.8312 10.405 11.7652 10.4633L5.59747 15.9132C4.84401 16.599 4.78122 17.7834 5.53468 18.4691C6.28815 19.2172 7.41834 19.1549 8.10902 18.4068L13.5995 12.2819C13.6577 12.217 13.751 12.1978 13.8318 12.2305C15.1873 12.7792 16.8103 12.5087 17.9041 11.3625Z" fill="white"/>',
    // Доставка у ваше місто"
    'labelId-5': '<circle cx="12" cy="12" r="12" fill="#256799"/><path d="M6.00008 6.6665C5.64646 6.6665 5.30732 6.80698 5.05727 7.05703C4.80722 7.30708 4.66675 7.64622 4.66675 7.99984V15.3332H6.00008C6.00008 15.8636 6.2108 16.3723 6.58587 16.7474C6.96094 17.1225 7.46965 17.3332 8.00008 17.3332C8.53051 17.3332 9.03922 17.1225 9.41429 16.7474C9.78937 16.3723 10.0001 15.8636 10.0001 15.3332H14.0001C14.0001 15.8636 14.2108 16.3723 14.5859 16.7474C14.9609 17.1225 15.4696 17.3332 16.0001 17.3332C16.5305 17.3332 17.0392 17.1225 17.4143 16.7474C17.7894 16.3723 18.0001 15.8636 18.0001 15.3332H19.3334V11.9998L17.3334 9.33317H15.3334V6.6665H6.00008ZM10.6667 7.99984L13.3334 10.6665L10.6667 13.3332V11.3332H6.66675V9.99984H10.6667V7.99984ZM15.3334 10.3332H17.0001L18.3134 11.9998H15.3334V10.3332ZM8.00008 14.3332C8.2653 14.3332 8.51965 14.4385 8.70719 14.6261C8.89472 14.8136 9.00008 15.068 9.00008 15.3332C9.00008 15.5984 8.89472 15.8527 8.70719 16.0403C8.51965 16.2278 8.2653 16.3332 8.00008 16.3332C7.73486 16.3332 7.48051 16.2278 7.29297 16.0403C7.10544 15.8527 7.00008 15.5984 7.00008 15.3332C7.00008 15.068 7.10544 14.8136 7.29297 14.6261C7.48051 14.4385 7.73486 14.3332 8.00008 14.3332ZM16.0001 14.3332C16.2653 14.3332 16.5197 14.4385 16.7072 14.6261C16.8947 14.8136 17.0001 15.068 17.0001 15.3332C17.0001 15.5984 16.8947 15.8527 16.7072 16.0403C16.5197 16.2278 16.2653 16.3332 16.0001 16.3332C15.7349 16.3332 15.4805 16.2278 15.293 16.0403C15.1054 15.8527 15.0001 15.5984 15.0001 15.3332C15.0001 15.068 15.1054 14.8136 15.293 14.6261C15.4805 14.4385 15.7349 14.3332 16.0001 14.3332Z" fill="white"/>',
    // TODO: Default value -> it needs to change
    'default': '<circle cx="12" cy="12" r="12" fill="#256799"/><path d="M6.00008 6.6665C5.64646 6.6665 5.30732 6.80698 5.05727 7.05703C4.80722 7.30708 4.66675 7.64622 4.66675 7.99984V15.3332H6.00008C6.00008 15.8636 6.2108 16.3723 6.58587 16.7474C6.96094 17.1225 7.46965 17.3332 8.00008 17.3332C8.53051 17.3332 9.03922 17.1225 9.41429 16.7474C9.78937 16.3723 10.0001 15.8636 10.0001 15.3332H14.0001C14.0001 15.8636 14.2108 16.3723 14.5859 16.7474C14.9609 17.1225 15.4696 17.3332 16.0001 17.3332C16.5305 17.3332 17.0392 17.1225 17.4143 16.7474C17.7894 16.3723 18.0001 15.8636 18.0001 15.3332H19.3334V11.9998L17.3334 9.33317H15.3334V6.6665H6.00008ZM10.6667 7.99984L13.3334 10.6665L10.6667 13.3332V11.3332H6.66675V9.99984H10.6667V7.99984ZM15.3334 10.3332H17.0001L18.3134 11.9998H15.3334V10.3332ZM8.00008 14.3332C8.2653 14.3332 8.51965 14.4385 8.70719 14.6261C8.89472 14.8136 9.00008 15.068 9.00008 15.3332C9.00008 15.5984 8.89472 15.8527 8.70719 16.0403C8.51965 16.2278 8.2653 16.3332 8.00008 16.3332C7.73486 16.3332 7.48051 16.2278 7.29297 16.0403C7.10544 15.8527 7.00008 15.5984 7.00008 15.3332C7.00008 15.068 7.10544 14.8136 7.29297 14.6261C7.48051 14.4385 7.73486 14.3332 8.00008 14.3332ZM16.0001 14.3332C16.2653 14.3332 16.5197 14.4385 16.7072 14.6261C16.8947 14.8136 17.0001 15.068 17.0001 15.3332C17.0001 15.5984 16.8947 15.8527 16.7072 16.0403C16.5197 16.2278 16.2653 16.3332 16.0001 16.3332C15.7349 16.3332 15.4805 16.2278 15.293 16.0403C15.1054 15.8527 15.0001 15.5984 15.0001 15.3332C15.0001 15.068 15.1054 14.8136 15.293 14.6261C15.4805 14.4385 15.7349 14.3332 16.0001 14.3332Z" fill="white"/>',

};
module.exports = {
    svg,
    getSvg: (elements = []) => findElements(svg, elements),
};
