import { mapActions, mapGetters } from "vuex";
import bpm from '@bu-auto-modules/bpm';
import { get } from '../../../helpers/cookie';

export default {
    name: 'ImpressionPopup',
    components: {
        Popup: require('../../Common/Popup.vue').default,
        'login-form': require('../../Common/LoginForm/index.vue').default,
    },
    data() {
        return {
            selectedReason: {},
            images: [],
            message: '',
            imageError: '',
            contact: '',
            ratingId: 0,
            imageLinks: [],
        }
    },
    computed: {
        ...mapGetters({
            popup_: 'popups/popup',
            getReasonsByType_: 'impression/getReasonsByType',
            getUserData: 'Common/userData',
        }),
        userData() {
            return this.getUserData || {};
        },
        data() {
            return this.popup_.data || {};
        },
        type() {
            return this.popup_.type || '';
        },
        sellerId() {
            return this.data.sellerId || 0;
        },
        rate() {
            return this.data.rate || 0;
        },
        reasons() {
            let reasons = this.getReasonsByType_(this.type) || {};
            //для оцінки продавця
            if (this.type === 'seller' && this.rate) {
                if (this.rate > 3) {
                    reasons = reasons['positive'];
                } else {
                    reasons = reasons['negative'];
                }
            }
            return reasons;
        },
        title() {
            return this.userData.userId ?
                this.type === 'seller' ? this.rate > 3 ? this.$t('Расскажите больше об этом продавце?') : this.$t('Что именно вам не понравилось?') : this.$t('Расскажите правду про это авто') : this.$t('Войдите на AUTO.RIA, чтобы поделиться правдой об авто или продавце');
        },
        textareaPlaceholder() {
            return this.userData.userId ?
                this.type === 'seller' ? this.rate < 3 ? this.$t('Расскажите нам, почему вы считаете, что') + ` ${this._result(this.selectedReason, 'title', '').toLowerCase()}?` : this.$t('Ваш комментарий') : this.$t('Расскажите нам, почему вы считаете, что')  + ` ${this._result(this.selectedReason, 'title', '').toLowerCase()}?` : this.$t('Ваш комментарий');
        },
        bpmData() {
            return {
                userInfo: {
                    phones: (this.userData.userPhones || []).join(',') || '',
                    email: this.userData.userEmail || '',
                    fio: this.userData.userName || 'guest',
                    user_id: this.userData.userId || 0,
                    ui: get('ui'),
                },
                appealInfo: {
                    titleFeedback: this.rate ? 'Оценка продавца(web) ' : this.selectedReason.title,
                    textFeedback: this.message,
                    complaintType: this.rate ? 'Оценка продавца(web) ' + this.rate  + ' - ' + this.selectedReason.title : this.selectedReason.title,
                    typeReview: 1,
                    link: `https://auto.ria.com${this.$route.fullPath}`,
                    internetInspector: 1,
                },
                deviceInfo: {
                    userAgent: navigator.userAgent,
                    isMobile: Number(this.isMobile),
                    appVersion: navigator.appVersion,
                    platform: navigator.platform,
                    screenWidth: screen.width,
                    screenHeight: screen.height,
                },
                advertisementInfo: {
                    markaName: '',
                    modelName: '',
                    year: 0,
                    sellerPrice: 0,
                    userId: this.userData.userId,
                    ratingId: this.ratingId,
                    rating: this.rate,
                    files: this.imageLinks,
                    autoHash: !this.rate ? this.selectedReason.hash : '',
                    sellerHash: this.rate ? this.selectedReason.hash : '',
                    link: `https://auto.ria.com${this.$route.fullPath}`,
                    id: 0,
                    phones: (this.userData.userPhones || []).join(',') || '',
                    ownerName: this.userData.userName,
                    photoLink: '',
                },
                sendParams: {
                    reasonId: this.selectedReason.id,
                    reasonName: this.selectedReason.title,
                    sendToCM: false,
                    sendToBPM: Boolean(((this.message && Number(this.rate) !== 10) || Number(this.rate) <= 7) && (this.userData.userEmail || this.userData.userPhones)),
                },
            }
        }
    },
    methods: {
        ...mapActions({
            close_: 'popups/close',
            getReasons_: 'impression/getReasons',
            rateUser_: 'impression/rateUser',
            downloadInspectorPics_: 'impression/downloadInspectorPics',
        }),
        close(){
            // видаляємо при закриті з пам'яті картинки
            if (this.images.length) {
                this.images.forEach(({url}) => URL.revokeObjectURL(url))
            }
            return this.close_();
        },
        getReasons() {
            switch (this.type) {
                case 'seller':
                    this.getReasons_('all');
                    break;
                case 'auto':
                    this.getReasons_('auto');
                    break;
            }
        },
        getSelectedReason(reason) {
            this.selectedReason = reason;
        },
        reset() {
            this.selectedReason = {};
        },
        sendToBpm(){
            if (this.images.length) {
                let imagesFormData = new FormData();
                this.images.slice(0, 2);
                this.images.forEach(({file}, i) => {
                    imagesFormData.append('file' + i, file);
                });
                this.downloadInspectorPics_(imagesFormData)
                    .then(({ files = [] } = {}) => {
                        this.imageLinks = files;
                        bpm.sendImpressions(this.bpmData.userInfo, this.bpmData.appealInfo, this.bpmData.deviceInfo, this.bpmData.advertisementInfo, '/users/sendImpressions/', this.bpmData.sendParams)
                            .then(() => this.close());
                    });
            } else {
                bpm.sendImpressions(this.bpmData.userInfo, this.bpmData.appealInfo, this.bpmData.deviceInfo, this.bpmData.advertisementInfo, '/users/sendImpressions/', this.bpmData.sendParams)
                    .then(() => this.close());
            }
        },
        sendImpression(){
            switch (true) {
                // Оцінка продавця
                case Boolean(this.type === 'seller' && this.rate):
                    this.rateUser_({ownerId: this.sellerId, value: this.rate}).then(({ ratingId = 0 } = {}) => {
                        this.$emit('slonikLogs', 880, 7, 1, {assessment: this.rate});
                        this.ratingId = ratingId;
                        this.sendToBpm();
                    });
                    break;
                // Скарга на авто
                case Boolean(this.type === 'auto'):
                    this.sendToBpm();
                    break;
                default:
                    console.log('Oops something went wrong..')
            }
        },
        imageHandler(e){
            const file = e.target.files[0];
            const fileSizeMB = this._result(file, 'size', 0) / 1024 / 1024;
            const fileType = file.type || '';
            const isImg = /jpg|png|jpeg/.test(fileType);
            if (!isImg) return;
            if (Number(fileSizeMB) > 10) {
                this.imageError = this.$t('Більше 10 Мб');
            } else {
                this.images.push({file, url: URL.createObjectURL(file)});
            }
        },
        deleteImage(image) {
            this.images = this.images.filter(({url}) => url !== image.url);
            URL.revokeObjectURL(image.url)
        },
    },
    mounted() {
        this.getReasons();
    },
    watch: {
        userData: {
            handler(newValue) {
                this.contact = this._result(newValue, 'userEmail', '') || (this._result(newValue, 'userPhones', [])[0] || '').replace(/[\s\(\))]/g, '');
            },
            immediate: true,
        }
    },
    i18n: require('./i18n').default,
}
