export default {
	messages: {
		ru: {
			'html.buAuto.iz': 'из',
			'html.buAuto.smotretvse': 'Смотреть все',
			'html.buAuto.fotografii': 'фотографий',
		},
		uk: {
			'html.buAuto.iz': 'з',
			'html.buAuto.smotretvse': 'Переглянути всі',
			'html.buAuto.fotografii': 'фотографій',
		},
	}
};
