export default {
	messages: {
		ru: {
			'Вернуться к результатам поиска': 'Вернуться к результатам поиска',
			'Авто от Банков': 'Авто от Банков',
			'Автодилер': 'Автодилер',
			'Заказ Авто': 'Заказ Авто',
			'Проверенный': 'Проверенный',
			'Проверенные': 'Проверенные',
			'в городе': 'в городе',
			'Авто от банка': 'Авто от банка',
			'Авто под заказ': 'Авто под заказ',
			'График работы': 'График работы',
			'с': 'с',
			'Оцените продавца': 'Оцените продавца',
			'Звоните': 'Звоните',
            'Свернуть': 'Свернуть',
            'Читать полностью': 'Читать полностью',
			'Выходной': 'Выходной',
			'Вс': 'Вс',
			'от': 'от',
			'Получай первым новые предложения': 'Получай первым новые предложения',
			'з послугою Автовикупу': 'c услугой Автовыкупа',
		},
		uk: {
			'Вернуться к результатам поиска': 'Повернутися до результатів пошуку',
			'Авто от Банков': 'Авто від Банків',
			'Автодилер': 'Автодилер',
			'Заказ Авто': 'Заказ Авто',
			'Проверенный': 'Перевірений',
			'Проверенные': 'Перевірені',
			'в городе': 'у місті',
			'Авто от банка': 'Авто від банку',
			'Авто под заказ': 'Авто під замовлення',
			'График работы': 'Графік роботи',
			'с': 'з',
			'Оцените продавца': 'Оцініть продавця',
			'Звоните': 'Телефонуйте',
            'Свернуть': 'Згорнути',
            'Читать полностью': 'Читати повністю',
			'Выходной': 'Вихідний',
			'Вс': 'Нд',
			'от': 'від',
			'Получай первым новые предложения': 'Отримуй першим нові пропозиції',
			'з послугою Автовикупу': 'з послугою Автовикупу',
		},
	}
};
