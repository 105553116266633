import { Loader } from '@googlemaps/js-api-loader';
import { mapGetters } from 'vuex';

export default {
	name: 'GoogleMap',
	props: {
		mapConfig: {
			type: Object,
			// https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
			default() {
				return {
					zoom: 12,
				};
			}
		},
		center: {
			type: Object,
			default() {
				return {
					// Kiev
					lat: 50.450001,
					lng: 30.523333
				};
			}
		},
		markersList: {
			type: Array,
			// https://developers.google.com/maps/documentation/javascript/reference#marker
			default() {
				return [];
			},
		},
		mapClass: {
			type: String,
			default() {
				return 'google-map';
			}
		},
		floatMarkerSettings: {
			type: Object,
			default() {
				return {
					enable: false,
					dynamicCoordinates: false,
				};
			}
		}
	},
	data() {
		return {
			google: null,
			map: null,
			markers: [],
			renderedMarkers: [],
			myFloatMarker: null,
		};
	},
	computed: {
		...mapGetters({
			langCode: 'lang/code'
		})
	},
	mounted() {
		const loader = new Loader({
			apiKey: 'AIzaSyAWLPTt4zeHict2MKD0nJqOr0Alh1ic2rQ',
			version: 'weekly',
			language: this.langCode
		});
		loader.load().then(google => {
			this.google = google;
			this.initializeMap();
			this.drawMarkers();
		}).catch(e => console.error('ERROR:::loader.load()', e.message));
	},
	methods: {
		initializeMap() {
			const mapContainer = this.$refs.googleMap;
			this.map = new this.google.maps.Map(
				mapContainer, {
					...this.mapConfig,
					center: {
						lat: this.center.lat,
						lng: this.center.lng
					},
				}
			);
			if (this.floatMarkerSettings.enable) {
				this.floatMarker(this.center);
			}
		},
		drawMarkers() {
			if (this.markersList.length) {
				this.markersList.forEach((marker = {}) => {
					const { position: { lat = 0, lng = 0 } = {} } = marker;
					const uniqueMarkerId = lat + lng;
					if (!this.renderedMarkers.includes(uniqueMarkerId)) {
						this.renderedMarkers.push(uniqueMarkerId);
						const m = new this.google.maps.Marker({
							...marker,
							map: this.map,
						});
						this.markers.push(m);
					}
				});
			}
		},
		setCenterByAddress(address) {
			if (this.google) {
				return new Promise((resolve, reject) => {
					const geocoder = new this.google.maps.Geocoder();
					const addressWithCountry = address + ` ${this.$t('Украина')}`;
					geocoder.geocode({ address: addressWithCountry }).then(({results = []}) => {
						const { geometry = {} } = results[0];
						if (this.map) {
							this.map.setCenter(geometry.location);
							const coordinates = {
								lat: geometry.location.lat(),
								lng: geometry.location.lng()
							};
							this.floatMarker(coordinates);
							resolve(coordinates);
						}
					}).catch((e) => {
						reject('Geocode was not successful for the following address: ' + e);
						console.log('Geocode was not successful for the following address: ' + e);
					});
				});
			}
		},
		getAddressByCoords(location) {
			return new Promise((resolve, reject) => {
				const geocoder = new this.google.maps.Geocoder();
				geocoder.geocode({ location }).then(({ results = []} = {}) => {
					const addressWithLang = results.find(({plus_code}) => plus_code);
					const { plus_code: { compound_code = '' } = {} } = addressWithLang;
					resolve(compound_code);
				}).catch((e) => reject('Geocode was not successful for the following location:' + e));
			});
		},
		getMapStatus(status) {
			this.$emit('getMapStatus', Boolean(status));
		},
		deleteAllMarkers() {
			this.markers.forEach(marker => {
				if (!marker.notDelete) {
					marker.setMap(null);
				}
			});
		},
		floatMarker(coordinates) {
			if (this.floatMarkerSettings.enable) {
				if (!this.myFloatMarker) {
					this.myFloatMarker = new this.google.maps.Marker({
						draggable: true,
						position: {
							lat: coordinates.lat,
							lng: coordinates.lng
						},
						map: this.map,
					});
					return this.myFloatMarker;
				} else {
					this.myFloatMarker.setPosition(coordinates);
				}
			}
		},
		coordsDraggableMarker(marker) {
			this.google.maps.event.addListener(marker, 'dragend', (event = {}) => {
				const { latLng } = event;
				const coordinates = {
					lat: latLng.lat(),
					lng: latLng.lng()
				};
				this.$emit('coordsDraggableMarker', coordinates);
			});
		},
	},
	watch: {
		markersList() {
			if (this.google) {
				this.drawMarkers();
			}
		},
		center(newValue) {
			if (this.map) {
				this.map.setCenter(newValue);
			}
		},
		map(nawValue) {
			this.getMapStatus(nawValue);
		},
		myFloatMarker(newValue) {
			// eslint-disable-next-line max-len
			if (newValue && this.floatMarkerSettings.enable && this.floatMarkerSettings.dynamicCoordinates) {
				this.coordsDraggableMarker(this.myFloatMarker);
			}
		}
	},
	i18n: {
		messages: {
			ru: {
				'Украина': 'Украина'
			},
			uk: {
				'Украина': 'Україна'
			},
		}
	}
};
