export default {
    messages: {
        ru: {
            'Инспектор AUTO.RIA': 'Инспектор AUTO.RIA',
            'проверил': 'проверил',
            'адрес и актуальность контактов': 'адрес и актуальность контактов',
            'наличие компании в гос.реестре (ЕГР)': 'наличие компании в гос.реестре (ЕГР)',
            'сделал панорамные фото площадки': 'сделал панорамные фото площадки',
            'Отдел продаж': 'Отдел продаж',
            'Рейтинг продавца': 'Рейтинг продавца',
            'из': 'из',
            'работает, сегодня до': 'работает, сегодня до',
            'Выходной': 'Выходной',
            'Вс': 'Вс',
            'Написать в чат': 'Написать в чат',
            'Перейти на сайт компании': 'Перейти на сайт компании',
		  	'Адрес компании': 'Адрес компании',
		  	'Получай первым новые предложения': 'Получай первым новые предложения',
		  	'от': 'от',
            'Понеділок': 'Понедельник',
            'Вівторок': 'Вторник',
            'Середа': 'Среда',
            'Четвер': 'Четверг',
            "П'ятниця": 'Пятница',
            'Субота': 'Суббота',
            'Неділя': 'Воскресенье',
        },
        uk: {
            'Инспектор AUTO.RIA': 'Інспектор AUTO.RIA',
            'проверил': 'перевірив',
            'адрес и актуальность контактов': 'адреса і актуальність контактів',
            'наличие компании в гос.реестре (ЕГР)': 'наявність компанії в держ.реєстрі (ЄДР)',
            'сделал панорамные фото площадки': 'зробив панорамні фото майданчика',
            'Отдел продаж': 'Відділ продажу',
            'Рейтинг продавца': 'Рейтинг продавця',
            'из': 'з',
            'работает, сегодня до': 'працює, сьогодні до',
            'Выходной': 'Вихідний',
            'Вс': 'Нд',
            'Написать в чат': 'Написати в чат',
            'Перейти на сайт компании': 'Перейти на сайт компанії',
			'Адрес компании': 'Адреса компанії',
			'Получай первым новые предложения': 'Отримуй першим нові пропозиції',
            'от': 'від',
            'Понеділок': 'Понеділок',
            'Вівторок': 'Вівторок',
            'Середа': 'Середа',
            'Четвер': 'Четвер',
            "П'ятниця": "П'ятниця",
            'Субота': 'Субота',
            'Неділя': 'Неділя',
        },
    }
};
