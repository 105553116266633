import Vue from 'vue'
import _laxios from '../../_laxios'

export default {
    namespaced: true,
    state: Object.assign({
        response: {},
    }, __INITIAL_STATE__.seo),
    actions: {
        fetch({commit, rootGetters, getters}, params = {}) {

            let {'lang/id': langId} = rootGetters;
            const {id} = params;
            params = Object.assign({}, params, {langId});
            return _laxios.seoCompany.expandUrl().request({params})
                .then(payload => {
                        commit('save', {payload, id});
                        return payload;
                    }
                )
                .catch((e)=> console.error('Ошибка при получении company/seo', e));
        }
    },
    mutations: {
        save: (state, {payload, id} = {}) => {
            Vue.set(state.response, id, payload);
        }
    },
    getters: {
        getSeo(state) {
            return function (id) {
                return state.response[id];
            }
        },
    },
}