'use strict';
import Vue from 'vue';
import {mapGetters} from 'vuex';

import loopify from '../../../../app/helpers/loopify';
const VISIBLE_INIT = 7;

module.exports = {
	i18n: require('./i18n').default,
	data() {
		return {
			expanded: false,
			loaded: false,
			positionTotal: 0
		};
	},
	components: {
		Panorama: require('./Panorama/index.vue').default
	},
	computed: {
		...mapGetters({
			getCompany: 'companies/company'
		}),
		id() {
			return this.$route.params.id;
		},
		company() {
			return this.getCompany(this.id) || {};
		},
		name() {
			const {name: {original: name} = {}} = this.company;
			return name;
		},
		panoramas() {
			return Array.isArray(this.company.panoramas) ? this.company.panoramas.map((src = {}, index) => ({index, src, type: 'panorama'})) : [];
		},
		photos() {
			return Array.isArray(this.company.photos) ? this.company.photos.map((src = {}, index) => ({index, src, type: 'photo'})) : [];
		},
		listFull() {
			return this.panoramas.concat(this.photos).map((item = {}, index) => Object.assign(item, {indexTotal: index}));
		},
		listDesktopVisible() {
			return this.expanded ? this.listFull : this.listFull.slice(0, VISIBLE_INIT);
		},
		itemCurrent() {
			return this.item(this.positionTotal) || {};
		},
		mode() {
			return this.itemCurrent.type;
		},
		modeListCurrent() {
			return this.modeListCurrent(this.mode);
		}
	},
	methods: {
		goto(position) {
			const positionNew = loopify(position, this.listFull.length);
			if (this.positionTotal !== positionNew) {
				this.loaded = false;
				this.positionTotal = loopify(position, this.listFull.length);
			}
		},
		next() {
			this.goto(this.positionTotal + 1);
		},
		prev() {
			this.goto(this.positionTotal - 1);
		},
		gotoMode(mode, position = 0) {
			const modeList = mode === 'panorama' ? this.panoramas : this.photos;
			if (modeList.length > position) {
				const item = modeList[position];
				this.goto(item.indexTotal);
			}
		},
		item(position) {
			return this.listFull[position] || {};
		},
		modeList(mode) {
			return mode === 'panorama' ? this.panoramas : this.photos;
		},
		onLoad(position) {
			Vue.set(this.loaded, position, true);
		}
	},
	mounted() {
		if (this.isDesktop) {
			this.gotoMode('panorama');
		} else {
			this.gotoMode('photo');
		}
	}
};
