import {mapActions, mapGetters} from 'vuex';

const INITIAL_LIMIT = 4;

module.exports = {
	i18n: require('./i18n').default,
	init({store, route = {}}) {
		const {to: {params: {id} = {}} = {}} = route;
		const {autos} = store.getters['companies/company'](id) || {};
		if (Array.isArray(autos)) {
			return	Promise.all(autos.slice(0, INITIAL_LIMIT).map((id) => store.dispatch('search/items/v2', id).catch(console.error)));
		}
	},
	data() {
		return {
			limit: INITIAL_LIMIT
		};
	},
	components: {
		Offer: require('./Offer/index.vue').default
	},
	computed: {
		...mapGetters({
			getCompany: 'companies/company'
		}),
		id() {
			return this.$route.params.id;
		},
		company() {
			return this.getCompany(this.id) || {};
		},
		offers() {
			return Array.isArray(this.company.autos) ? this.company.autos : [];
		},
		companyAutos() {
			const {id, type: {id: typeId} = {}} = this.company;
			return typeId === 3 ? `/search-order-auto/?company_id=${id}&custom=3&abroad=1` : `/search/?company_id=${id}&custom=3&abroad=2`;
		},
		offersVisible() {
			return this.offers.slice(0, this.limit);
		}
	},
	methods: {
		...mapActions({
			getOfferData: 'search/items/v2'
		}),
		slonikElevator(event, action, block, payload) {
			this.$emit('slonikLogs', event, action, block, payload);
		},
	}
};
