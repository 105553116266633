import { mapActions, mapGetters } from 'vuex';
const { images: { jpeg } } = require('../../../../helpers/patterns');

module.exports = {
	props: {
		id: Number
	},
	data() {
		return {
			loaded: false
		};
	},
	computed: {
		...mapGetters({
			getItem: 'search/items/v2'
		}),
		data() {
			return this.getItem(this.id) || {};
		},
		price() {
			let price = {};
			const mainCurrency = this._result(this.data, 'autoData.mainCurrency', '');
			const symbol = {
				USD: ' $',
				UAH: ' грн',
				EUR: ' €',
			};
			Object.assign(price, {
				main: `${this.formatNumber(this.data[mainCurrency] || 0)} ${symbol[mainCurrency]}`,
				second: `${mainCurrency !== 'UAH' ? this.formatNumber(this.data['UAH'] || 0) + symbol['UAH'] : this.formatNumber(this.data['USD'] || 0) + symbol['USD']}`,
			});
			return price;
		},
		webp() {
			return this._result(this.data, 'photoData.seoLinkF', '').replace(jpeg, '.webp');
		}
	},
	methods: {
		...mapActions({
			getOfferData: 'search/items/v2'
		}),
		slonLogs() {
			this.$emit('slonikLogs', 880, 2, 4, { proposal_id: this.id });
		},
	},
	mounted() {
		return this.getOfferData(this.id);
	},
	i18n: require('../i18n').default,
};
