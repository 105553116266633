import { mapActions } from "vuex";

export default {
    name: 'Phone',
    components: {
        PhonePopup: require('../PhonePopup/index.vue').default,
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            showNumber: false,
        }
    },
    computed: {
        phone() {
          return this.showNumber ? this.data.phone_formatted : `(${this.data.phone_code}) xxx xx xx`;
        },
    },
    methods: {
        ...mapActions({
            openPopup_: 'popups/open',
        }),
        openPopup() {
            this.openPopup_({ name: 'PhonePopup' });
            this.showNumber = true;
            this.$emit('slonikLogs', 32, 2, 1);
        }
    },
}
