module.exports = {
	namespaced: true,
	state: {
		popups: [
		]
	},
	getters: {
		popup({popups}) {
			if (popups.length) {
				return popups[0];
			}
		}
	},
	actions: {
		close({commit}) {
			commit('close');
		},
		open({commit}, payload) {
			commit('open', payload);
		}
	},
	mutations: {
		close(state) {
			state.popups.shift();
		},
		open(state, payload) {
			state.popups.push(payload);
		}
	}
};
