export default {
	messages: {
		ru: {
			'Модельный ряд': 'Модельный ряд',
		},
		uk: {
			'Модельный ряд': 'Модельний ряд',
		},
	}
};
