export default {
    messages: {
        ru: {
            'Что именно вам не понравилось?': 'Что именно вам не понравилось?',
            'Расскажите больше об этом продавце?': 'Расскажите больше об этом продавце?',
            'Расскажите правду про это авто': 'Расскажите правду про это авто',
            'Войдите на AUTO.RIA, чтобы поделиться правдой об авто или продавце': 'Войдите на AUTO.RIA, чтобы поделиться правдой об авто или продавце',
            'Ваш комментарий': 'Ваш комментарий',
            'Расскажите нам, почему вы считаете, что': 'Расскажите нам, почему вы считаете, что',
            'Добавить фото': 'Добавить фото',
            'В формате png, jpg, не более двух': 'В формате png, jpg, не более двух',
            'Сообщить': 'Сообщить',
            'Выбрать другую причину': 'Выбрать другую причину',
        },
        uk: {
            'Что именно вам не понравилось?': 'Що саме вам не сподобалося?',
            'Расскажите больше об этом продавце?': 'Розкажіть більше про цього продавця?',
            'Расскажите правду про это авто': 'Розкажіть правду про це авто',
            'Войдите на AUTO.RIA, чтобы поделиться правдой об авто или продавце': 'Увійдіть на AUTO.RIA, щоб поділитися правдою про авто чи продавця',
            'Ваш комментарий': 'Ваш коментар',
            'Расскажите нам, почему вы считаете, что': 'Розкажіть нам, чому ви вважаєте, що',
            'В формате png, jpg, не более двух': 'В форматі png, jpg, не більше двох',
            'Сообщить': 'Повідомити',
            'Выбрать другую причину': 'Вибрати іншу причину',
        },
    }
};
